<template>
  <div class="journey-detail-member-edit">
    <div class="team-members-number with-hr d-flex justify-content-between align-items-center">
      <div class="fs-body-16">開放參團</div>
      <b-form-checkbox v-model="isJoinOpen" name="check-button" switch>
      </b-form-checkbox>
    </div>
    <div class="team-members-number with-hr d-flex justify-content-between align-items-center">
      <div class="fs-body-16">人數設定</div>
      <b-form-select v-model="selectedCapacity" :options="numberOptions"></b-form-select>
    </div>
    <div class="team-members-number with-hr d-flex align-items-center">
      <div class="avatar-group">
        <div class="avatar circle-trail">
          <img :src="joinedOwner.pic_url" alt="avatar">
        </div>
      </div>
      <div class="fs-body-14">
        <span>主辦人</span>
      </div>
    </div>

    <div class="team-members-number with-hr d-flex" v-for="(item, index) in joinedMembers" :key="index">
      <div class="avatar-group">
        <div class="avatar circle-trail">
          <img :src="item.user.pic_url" alt="avatar">
        </div>
        <div class="edit-link" v-if="item.user.serial === user.serial && !isExpired">
          <a :href="'/journey/' + journey._id + '/join'">編輯</a>
        </div>
      </div>
      <div class="no-permission fs-body-14 align-items-center d-flex" v-if="isExpired || !item.realName">
        <span>無瀏覽資料權限</span>
      </div>
      <!--								<div class="no-permission fs-body-14 align-items-center d-flex" v-if="!isExpired && !item.realName">-->
      <!--									<span>無瀏覽資料權限</span>-->
      <!--								</div>-->
      <div class="detail-list fs-body-14 align-items-center" v-if="!isExpired">
        <div class="detail-item cursor-pointer" v-if="item.realName" @click="getTextCopy($event)">
          <div class="item-title">姓名</div>
          <div class="item-desc" v-text="item.realName"></div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" v-if="item.phone" @click="getTextCopy($event)">
          <div class="item-title">電話</div>
          <div class="item-desc" v-text="item.phone"></div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" v-if="item.address" @click="getTextCopy($event)">
          <div class="w-100 d-flex flex-nowrap">
            <div class="item-title">地址</div>
            <div class="item-desc" v-text="(item.fullAddress && item.fullAddress.length>0)? item.fullAddress : item.address"></div>
          </div>
          <span class="success-alert"></span>
        </div>

        <div class="detail-item cursor-pointer" v-if="item.email" @click="getTextCopy($event)">
          <div class="item-title">Email</div>
          <div class="item-desc" v-text="item.email"></div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" v-if="item.personalId" @click="getTextCopy($event)">
          <div class="item-title">身分證字號</div>
          <div class="item-desc" v-text="item.personalId"></div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" v-if="item.birthday" @click="getTextCopy($event)">
          <div class="item-title">出生年月日</div>
          <div class="item-desc" v-text="item.birthday"></div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" v-if="item.emergencyName" @click="getTextCopy($event)">
          <div class="item-title">緊急聯絡人</div>
          <div class="item-desc" v-text="item.emergencyName"></div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" v-if="item.emergencyPhone" @click="getTextCopy($event)">
          <div class="item-title">緊急聯絡人電話</div>
          <div class="item-desc" v-text="item.emergencyPhone"></div>
          <span class="success-alert"></span>
        </div>

        <div class="detail-item cursor-pointer" style="display: none;">
          <div class="w-100 d-flex flex-nowrap">
            <div class="item-title">地址區域碼</div>
            <div class="item-desc" v-text="(item.cityCode && item.cityCode.length>0)? item.cityCode : ''"></div>
          </div>
          <span class="success-alert"></span>
        </div>
        <div class="detail-item cursor-pointer" style="display: none;">
          <div class="w-100 d-flex flex-nowrap">
            <div class="item-title">剩餘地址</div>
            <div class="item-desc" v-text="item.address"></div>
          </div>
          <span class="success-alert"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import moment from "moment/moment";

export default {
  name: 'JourneyDetailMemberEdit',
  data() {
    return {
      isJoinOpen: false,
      selectedCapacity: 8,
      membersMax: 10,
    };
  },
  props: {
    journeyData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    joinedOwner: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    joinedMembers: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  components: {
  },
  computed: {
    ...mapState(['user']),

    isExpired: function() {
      const theDayAfterEndDate = moment(this.journeyData.endDate);
      theDayAfterEndDate.add(1, 'day');
      return moment().isAfter(theDayAfterEndDate);
    },
    numberOptions() {
      const options = [];
      for (let i = 1; i <= this.membersMax; i++) {
        options.push({ value: i, text: i.toString() });
      }
      return options;
    },
	},
  watch: {
    
  },
  mounted() {

	},
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.journey-detail-member-edit {
  @include smaller-than-medium {
    padding: 0 1rem;
  }
  .team-members-number {
    color: $color-bk-tp-85;
    padding: 36px 0;
    &.with-hr:not(:last-child) {
      border-bottom: 1px solid $color-black-200;
    }
    .no-permission {
      color: $color-bk-tp-25;
    }
    .avatar-group {
      flex: 0 0 auto;
      margin-right: 36px;
      .edit-link {
        text-align: center;
        margin-top: 8px;
      }
    }
  }
}
</style>
