<template>
  <div class="layout-main bg-white">
    <section class="journey edit-mode" v-if="journeyData">
      <div class="header journey-block">
        <b-button class="back-btn d-md-none" variant="link">
          <Icon name="arrow_back_ios" size="24"></Icon>
        </b-button>
        <div class="fs-h2 text-center">編輯登山計畫</div>
        <button class="finish-btn btn common-oval-btn dark fs-body-18">完成</button>
      </div>
      <div class="journey-name journey-block">
        <div class="fs-body-16">路線名稱</div>
        <div class="fs-body-18">{{ journeyData.name }}</div>
      </div>
      <div class="journey-content journey-block">
        <div class="block-title fs-h4">計畫內容</div>
        <div class="operating-list">
          <path-operating-Item class="cursor-pointer" :icon="operatingList.time.srcName" :name="operatingList.time.name" @itemClick="openDatePicker">
            <common-date-picker ref="commonDatePicker" v-model="journeyData.startDate" :open="datePickerOpen" @close="closeFlatpickr"></common-date-picker>
          </path-operating-Item>
          <journey-day-select-item v-model="journeyData.durationDays" :options="templateOptions" :name="operatingList.days.name" :icon="operatingList.days.src"></journey-day-select-item>
          <path-operating-Item class="cursor-pointer" :icon="operatingList.people.srcName" :name="operatingList.people.name" @itemClick="openPageModal">
            <div class="fs-body-16" v-text="joinedMembers.length + 1"></div>
          </path-operating-Item>
        </div>
      </div>
      <div class="journey-plan journey-block" v-if="journeyData">
        <div class="block-title fs-h4">路線規劃</div>
        <div class="content-list">
          <div class="content-item d-flex justify-content-between">
            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="directions_walk" size="20" />
                <div class="info-text fs-body-16">行走</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="directionWalkHr"></span>
                <span>:</span>
                <span v-text="directionWalkMin"></span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="distance" size="20" />
                <div class="info-text fs-body-16">距離</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="distance"></span>
                <span>km</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-up" size="20" />
                <div class="trail-info-text fs-body-16">總升</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="climbUp"></span>
                <span>m</span>
              </div>
            </div>

            <div class="d-flex flex-column align-items-center px-2">
              <div class="info-name d-flex align-items-center">
                <Icon class="info-icon" name="climb-down" size="20" />
                <div class="info-text fs-body-16">總降</div>
              </div>
              <div class="info-value fs-h2">
                <span v-text="climbDown"></span>
                <span>m</span>
              </div>
            </div>
          </div>
          <div class="content-item d-flex justify-content-center">
            <speed-controller v-model="journeyData.climbRate"></speed-controller>
          </div>
          <div class="content-item">
            <div class="journey-info row">
              <div class="col-12 col-md-6">
                <div class="box trail-map" :class="{ 'scale-active': mapScale }">
                  <img class="thumbnail d-md-none" :src="[journeyData.picUrl]" alt="路線縮圖">
                  <button class="btn scale d-md-none" @click="mapScaleToggle">
                    <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                  </button>
                  <div class="path-map-group custom-z-index common-transition">
                    <path-map ref="map" v-if="shownPathFullPosition.length > 0" :path="shownPathFullPosition" :milestones="shownPathMilestones"></path-map>
                    <button class="btn scale d-md-none" @click="mapScaleToggle">
                      <Icon :name="mapScale ? 'map-reduce' : 'map-enlarge'" size="24" alt="地圖縮放鍵" class="map-scale-btn" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box overflow-auto" v-if="generatedJourney">
                  <journey-viewer :journey="generatedJourney" :show-opt-btn="true" :show-weather="false" :show-start-time-select="true" @optclick="milestoneOptClicked" @start-time-changed="dayStartTimeChanged"></journey-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="journey-order journey-block">-->
<!--        <div class="block-title fs-h4">服務預訂</div>-->
<!--      </div>-->
    </section>
    <b-modal modal-class="rest-time" ref="diagram-modal" title="設定休息時間" size="sm" centered v-model="showRestModal" @ok="milestoneRestTimeModalSaveClicked">
      <select v-model="milestoneRestTime">
        <option v-for="(item, index) in milestoneRestTimeOpts" :key="index" :value="item" v-text="item + '分鐘'"></option>
      </select>
    </b-modal>
    <transition name="fade">
      <pageModal v-if="pageModalOpen" title="參與人數" option-btn-text="儲存" @close="closePageModal" >
        <JourneyDetailMemberEdit :journeyData="journeyData" :joinedOwner="joinedOwner" :joinedMembers="joinedMembers" />
      </pageModal>
    </transition>
  </div>
</template>

<script>
import setHideNav from "@/assets/js/setHideNav";
import PathOperatingItem from "@/views/path/PathOperatingItem.vue";
import JourneyDaySelectItem from "@/views/journey/JourneyDaySelectItem.vue";
import PathMap from "@/components/common/PathMap.vue";
import CommonDatePicker from "@/components/common/CommonDatePicker.vue";
import JourneyViewer from "@/components/common/JourneyViewer.vue";
import SpeedController from "@/components/common/SpeedController.vue";
import pageModal from "@/components/common/pageModal.vue";
import JourneyDetailMemberEdit from "@/views/journey/JourneyDetailMemberEdit.vue";
import svgEdit from "@/assets/images/icon/edit-gray.svg";
import moment from "moment/moment";

export default {
  mixins: [setHideNav],
  name: 'JourneyDetailEdit',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      operatingList: {
        time: { name:'出發日期', srcName: 'Date' },
        days: { name:'行程天數', srcName: 'Date' },
        people: { name:'參與人數', srcName: 'Group' },
      },

      journeyData: null,
      joinedOwner: {},
      joinedMembers: [],
      shownPathMilestones: [],
      shownPathFullPosition: [],
      shownPathFullHeights: [],

      datePickerOpen: false,
      pathTemplates: [],
      restAry: [],
      startTimes: [],

      showRestModal: false,
      milestoneRestTimeSettingIndex: -1,
      milestoneRestTime: 15,
      milestoneRestTimeOpts: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 60, 75, 90],

      pageModalOpen: false,
      mapScale: false,

      imageSrc: {
        svgEdit: svgEdit,
      },
    };
  },
  props: {
  },
  components: {
    SpeedController,
    CommonDatePicker,
    PathOperatingItem,
    JourneyDaySelectItem,
    PathMap,
    JourneyViewer,
    pageModal,
    JourneyDetailMemberEdit,
  },
  computed: {
    directionWalkHr() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return parseInt(this.journeyData.pathInfo.totalWalkTime/60);
      } else {
        return '';
      }
    },
    directionWalkMin() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return String(parseInt(this.journeyData.pathInfo.totalWalkTime%60)).padStart(2, '0');
      } else {
        return '';
      }
    },
    distance() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return (this.journeyData.pathInfo.totalDistance/1000).toFixed(1);
      } else {
        return '';
      }
    },
    climbUp() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return (this.journeyData.pathInfo.totalUp).toFixed(0);
      } else {
        return '';
      }
    },
    climbDown() {
      if(this.journeyData && this.journeyData.pathInfo) {
        return (this.journeyData.pathInfo.totalDown).toFixed(0);
      } else {
        return '';
      }
    },
    templateOptions: function() {
      return this.pathTemplates.map((template, index) => ({
        index: index,
        name: template.name,
        val: template.durationDays,
      }));
    },
    selectedTemplateIndex() {
      if (this.journeyData) {
        return this.pathTemplates.findIndex(template => template.durationDays === this.journeyData.durationDays);
      } else {
        return -1;
      }
    },
    selectedTemp: function() {
      if (this.selectedTemplateIndex === -1) {
        return null;
      }
      return this.pathTemplates[this.selectedTemplateIndex];
    },
    generatedJourney: function() {
      if(this.journeyData && this.selectedTemp) {
        const res = {... this.selectedTemp};

        // 起始日期與結束日期
        let cDate = moment(this.journeyData.startDate);
        res.startDate = cDate.format('YYYY-MM-DD');
        cDate.add(res.durationDays - 1, 'days');
        res.endDate = cDate.format('YYYY-MM-DD');

        //中間路途
        const newJourney = [];

        let stepTime = moment(`${res.startDate} ${this.startTimes[0]}`);
        let dayCount = 0;
        for (let i=0;i<this.selectedTemp.journey.length;i++) {
          const newJ = {... this.selectedTemp.journey[i]};
          newJ.startTimestamp = stepTime.valueOf();
          newJ.startTime = stepTime.format('YYYY-MM-DD HH:mm');

          if (res.journeySplitIndexs.indexOf(i) !== -1) {
            stepTime = moment(`${res.startDate} ${this.startTimes[dayCount+1]}`).add(dayCount, 'days');
            dayCount = dayCount + 1;
          } else {
            if (newJ.type === 'route') {
              const costMinutes = (newJ.direction === 0)?newJ.detail.consuming:newJ.detail.returnConsuming;
              const lastCostMinutes = costMinutes * this.journeyData.climbRate
              stepTime.add(lastCostMinutes, 'minutes');
            } else if (newJ.type === 'milestone') {
              const costMinutes = this.restAry[i/2];
              stepTime.add(costMinutes, 'minutes');
            }
          }
          newJ.endTimestamp = stepTime.valueOf();
          newJ.endTime = stepTime.format('YYYY-MM-DD HH:mm');

          newJ.duration = (newJ.endTimestamp - newJ.startTimestamp) * this.journeyData.climbRate / (60 * 1000);

          newJourney.push(newJ);
        }
        res.journey = newJourney;
        return res;
      } else {
        return null
      }
    },
	},
  watch: {
    selectedTemplateIndex: function() {
      this.reselectJourney();
    },
  },
  async mounted() {
    const journeyId = this.$route.params.id;
    await this.readInfo(journeyId);
    await this.reselectJourney();
	},
  methods: {
    async readInfo(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.journeyData = await this.$store.dispatch('api/getJourneyDetailPromise', journeyId);
        await this.refreshJoinedMemberList(journeyId);
        this.buildFullPathAndHeights(this.journeyData.journey);
        this.buildPathMilestones(this.journeyData.journey);
        const dayTemplatesRequest = await this.$store.dispatch('api/getPathDayTemplatePromise', this.journeyData.baseOnPathSerial);
        let templates = [];
        for (const dt of dayTemplatesRequest) {
          const templateDetail = await this.$store.dispatch('api/readJourneyDetailByTemplatePromise', dt.serial);
          templateDetail.tempId = dt.serial;
          templates.push(templateDetail);
        }
        templates = templates.sort(function(a, b) {
          return a.durationDays - b.durationDays;
        });
        this.pathTemplates.push(...templates);
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    refreshJoinedMemberList: async function(id) {
      const res = await this.$store.dispatch('api/getJourneyJoinSubmitListPromise', id);
      this.joinedMembers.splice(0, this.joinedMembers.length);
      if (res !== null) {
        this.joinedOwner = Object.assign({}, this.joinedOwner, res.owner);
        res.list.map((item) => {
          if (item.user.serial === this.user.serial) {
            this.joinedMembers.unshift(item);
          } else {
            this.joinedMembers.push(item);
          }
        })
      }
    },
    buildFullPathAndHeights(journeyItems) {
      this.shownPathFullPosition.splice(0, this.shownPathFullPosition.length);
      this.shownPathFullHeights.splice(0, this.shownPathFullHeights.length);

      journeyItems.forEach((item) => {
        if (item.type === 'milestone') {
          this.shownPathFullPosition.push({
            lat: item.detail.lat,
            lng: item.detail.lng,
          });
        } else if (item.type === 'route') {
          const detail = item.detail;
          const routepts = detail.routepts;
          const heights = detail.heights;

          if (item.direction === 0) {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights);
          } else {
            this.shownPathFullPosition = this.shownPathFullPosition.concat(
                routepts.reverse().map((point) => ({ lat: point.lat, lng: point.lng }))
            );
            this.shownPathFullHeights = this.shownPathFullHeights.concat(heights.reverse());
          }
        }
      });
    },
    buildPathMilestones(journeyItems) {
      this.shownPathMilestones.splice(0, this.shownPathMilestones.length);

      for (const item of journeyItems) {
        if (item.type === 'milestone') {
          const found = this.shownPathMilestones.filter((m) => {
            return m.serial === item.detail.serial;
          });
          if (found.length === 0) {
            this.shownPathMilestones.push(item.detail);
          }
        }
      }
    },
    reselectJourney: function() {
      if(this.selectedTemp.journey.length) {
        this.restAry.splice(0, this.restAry.length);
        this.startTimes.splice(0, this.startTimes.length);

        for (let i=0;i<this.selectedTemp.journey.length;i++) {
          const j = this.selectedTemp.journey[i];
          if (j.type === 'milestone') {
            this.restAry.push(0);
            if (i === 0 || this.selectedTemp.journeySplitIndexs.indexOf(i) !== -1) {
              this.startTimes.push(moment(j.endTimestamp).format('HH:mm'));
            }
          }
        }

        this.updateJourney();
      }
    },
    updateJourney: function() {
      this.buildFullPathAndHeights(this.selectedTemp.journey);
      this.buildPathMilestones(this.selectedTemp.journey);
    },
    openDatePicker() {
      this.$refs.commonDatePicker.openFlatpickr()
    },
    closeFlatpickr() {
      this.datePickerOpen = false;
    },
    milestoneOptClicked(index) {
      this.milestoneRestTimeSettingIndex = index/2;
      this.milestoneRestTime = this.restAry[this.milestoneRestTimeSettingIndex];
      this.showRestModal = true;
    },
    milestoneRestTimeModalSaveClicked() {
      const index = this.milestoneRestTimeSettingIndex;
      this.$set(this.restAry, index, this.milestoneRestTime);
    },
    dayStartTimeChanged(data) {
      this.$set(this.startTimes, data.index, data.startTime);
      // this.reselectJourney();
    },
    openPageModal() {
      this.pageModalOpen = true;
    },
    closePageModal() {
      this.pageModalOpen = false;
    },
    mapScaleToggle: function () {
      this.mapScale = !this.mapScale;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.journey.edit-mode {
  @include smaller-than-medium {
    .header.journey-block {
      padding: 12px;
      .btn.back-btn {
        position: absolute;
        top: 3px;
        left: 0;
        width: fit-content;
        padding: .5rem;
      }
      .fs-h2 {
        color: $color-bk-tp-85;
        font-size: 18px;
        line-height: 1.5;
      }
      .btn.finish-btn {
        top: 3px;
        right: 0.5rem;
        color: $color-bk-tp-85;
        background: $color-white;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5;
        border: none;
        padding: .5rem 1rem;
      }
    }
    .journey-block.journey-name {
      .fs-body-16 {
        margin-bottom: 12px;
      }
    }
    .block-title {
      margin-bottom: 12px;
    }
    .operating-item:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/journey";
</style>
